import React from "react"
import Layout from "../components/layout/layout"
import DMSlide from "../images/sketches/dm-slide.jpg"
import "./contact.less"

const ContactPage = () => {
  const title = "Contact"

  return (
    <Layout title={title} className="contact">
      <section
        className="contact-page"
        style={{ maxWidth: 720, margin: "0 auto" }}
      >
        <p>
          Want to get in touch?
          <br />
          Want to have a natter about food?
          <br />
          Want to give me constructive feedback?
          <br />
          Want me to draw you a custom doodle?
          <br />
          Want me to explain what I meant by "a pinch of X"?
          <br />
          Want me to become an influencer for your instant noodle brand?
          <br />
          Want me to stop writing sentences that start with "want"?
        </p>

        <div>
          <img src={DMSlide} />
        </div>

        <p className="instagram-handle">
          <a href="https://www.instagram.com/kitchenpan.co.uk/">
            @kitchenpan.co.uk
          </a>{" "}
          or email me at hellokitchenpan@gmail.com
        </p>
      </section>
    </Layout>
  )
}

export default ContactPage
